import React from "react";
import "./terms-of-service.css";

const TermsOfService = () => {
  return (
    <div className="terms-service-container">
      <h1 className="terms-service-title">Terms of Service</h1>
      <p className="terms-service-last-updated">
        <strong>Last Updated: 19/10/24</strong>
      </p>
      <p className="terms-service-intro">
        By accessing or using vwatch.in, you agree to comply with and be bound
        by these Terms of Service. If you do not agree with any part of these
        terms, you must not use our website.
      </p>

      <h2 className="terms-service-section-title">1. Changes to Terms</h2>
      <p className="terms-service-text">
        We reserve the right to modify these Terms of Service at any time. Any
        changes will be effective immediately upon posting on this page. Your
        continued use of the website after any modifications will constitute
        your acceptance of the revised terms.
      </p>

      <h2 className="terms-service-section-title">2. Use of the Website</h2>
      <ul className="terms-service-list">
        <li className="terms-service-list-item">
          You must be at least 13 years old to use this website.
        </li>
        <li className="terms-service-list-item">
          You agree to use the website only for lawful purposes and in
          accordance with applicable laws.
        </li>
        <li className="terms-service-list-item">
          You are prohibited from using the website in any way that violates any
          applicable federal, state, local, or international law.
        </li>
      </ul>

      <h2 className="terms-service-section-title">3. User Accounts</h2>
      <p className="terms-service-text">
        To access certain features of our website, you may need to create an
        account. You agree to provide accurate and complete information during
        the registration process and to update such information to keep it
        accurate, complete, and current. You are responsible for maintaining the
        confidentiality of your account password and for all activities that
        occur under your account.
      </p>

      <h2 className="terms-service-section-title">4. Intellectual Property</h2>
      <p className="terms-service-text">
        All content on vwatch.in, including text, graphics, logos, and images,
        is the property of vwatch.in or its content suppliers and is protected
        by copyright and intellectual property laws. You may not reproduce,
        distribute, or create derivative works without our express written
        consent.
      </p>

      <h2 className="terms-service-section-title">5. Disclaimers</h2>
      <p className="terms-service-text">
        The information provided on vwatch.in is for general informational
        purposes only. We do not warrant the accuracy, completeness, or
        usefulness of this information. Any reliance you place on such
        information is strictly at your own risk.
      </p>

      <h2 className="terms-service-section-title">
        6. Limitation of Liability
      </h2>
      <p className="terms-service-text">
        In no event shall vwatch.in, its directors, employees, or agents be
        liable for any indirect, incidental, special, consequential, or punitive
        damages arising out of or in connection with your access to or use of
        the website.
      </p>

      <h2 className="terms-service-section-title">7. Governing Law</h2>
      <p className="terms-service-text">
        These Terms of Service shall be governed by and construed in accordance
        with the laws of India, without regard to its conflict of law
        principles.
      </p>

      <h2 className="terms-service-section-title">8. Contact Us</h2>
      <p className="terms-service-text">
        If you have any questions about these Terms of Service, please contact
        us at{" "}
        <a
          href="mailto:vwatchhelp@gmail.com"
          className="terms-service-contact-email"
        >
          vwatch support
        </a>
        .
      </p>
    </div>
  );
};

export default TermsOfService;
