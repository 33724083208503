import React from "react";
import "./privacy-policy.css";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <h1 className="privacy-policy-title">Privacy Policy</h1>
      <p className="privacy-policy-last-updated">
        <strong>Last Updated: 19/10/24</strong>
      </p>
      <p className="privacy-policy-intro">
        Welcome to vwatch.in. We value your privacy and are committed to
        protecting your personal information. This Privacy Policy outlines how
        we collect, use, disclose, and protect your information when you visit
        our website.
      </p>

      <h2 className="privacy-policy-section-title">
        1. Information We Collect
      </h2>
      <p className="privacy-policy-text">
        We collect the following types of information when you use our services:
      </p>
      <ul className="privacy-policy-list">
        <li className="privacy-policy-list-item">
          <strong>Personal Information:</strong> When you create an account,
          subscribe to our newsletter, or contact us, we may collect personal
          information such as your name, email address, and phone number.
        </li>
        <li className="privacy-policy-list-item">
          <strong>Google Sign-In:</strong> If you choose to sign in to our app
          using Google, we request the following permissions:{" "}
          <code className="privacy-policy-code">
            ['openid', 'email', 'profile']
          </code>
          . This allows us to authenticate your identity and retrieve your basic
          profile information.
        </li>
        <li className="privacy-policy-list-item">
          <strong>Usage Data:</strong> We collect information about how you
          access and use our website, including your IP address, browser type,
          pages visited, and time spent on the site.
        </li>
      </ul>

      <h2 className="privacy-policy-section-title">
        2. How We Use Your Information
      </h2>
      <ul className="privacy-policy-list">
        <li className="privacy-policy-list-item">
          To provide and maintain our service
        </li>
        <li className="privacy-policy-list-item">
          To notify you about changes to our service
        </li>
        <li className="privacy-policy-list-item">
          To allow you to participate in interactive features
        </li>
        <li className="privacy-policy-list-item">
          To provide customer support
        </li>
        <li className="privacy-policy-list-item">
          To gather analysis or valuable information to improve our service
        </li>
        <li className="privacy-policy-list-item">
          To monitor the usage of our website
        </li>
        <li className="privacy-policy-list-item">
          To detect, prevent, and address technical issues
        </li>
        <li className="privacy-policy-list-item">
          To send you newsletters, marketing, or promotional materials
        </li>
      </ul>

      <h2 className="privacy-policy-section-title">
        3. Disclosure of Your Information
      </h2>
      <p className="privacy-policy-text">
        We do not sell or rent your personal information to third parties. We
        may share your information with:
      </p>
      <ul className="privacy-policy-list">
        <li className="privacy-policy-list-item">
          Service providers who assist us in our operations
        </li>
        <li className="privacy-policy-list-item">
          Law enforcement agencies if required by law
        </li>
        <li className="privacy-policy-list-item">
          Business transfers in the event of a merger or acquisition
        </li>
      </ul>

      <h2 className="privacy-policy-section-title">
        4. Security of Your Information
      </h2>
      <p className="privacy-policy-text">
        The security of your personal information is important to us. We
        implement various security measures to protect your data; however, no
        method of transmission over the Internet or electronic storage is 100%
        secure. We cannot guarantee its absolute security.
      </p>

      <h2 className="privacy-policy-section-title">5. Your Rights</h2>
      <ul className="privacy-policy-list">
        <li className="privacy-policy-list-item">
          The right to access, update, or delete the information we have on you
        </li>
        <li className="privacy-policy-list-item">
          The right to request that we restrict the processing of your personal
          information
        </li>
        <li className="privacy-policy-list-item">
          The right to data portability
        </li>
        <li className="privacy-policy-list-item">
          The right to withdraw consent
        </li>
      </ul>

      <h2 className="privacy-policy-section-title">
        6. Changes to This Privacy Policy
      </h2>
      <p className="privacy-policy-text">
        We may update our Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on this page. You are
        advised to review this Privacy Policy periodically for any changes.
      </p>

      <h2 className="privacy-policy-section-title">7. Contact Us</h2>
      <p className="privacy-policy-text">
        If you have any questions about this Privacy Policy, please contact us
        at{" "}
        <a
          href="mailto:vwatchhelp@gmail.com"
          className="privacy-policy-contact-email"
        >
          vwatch support
        </a>
        .
      </p>
    </div>
  );
};

export default PrivacyPolicy;
